/* Primera parte, Hero*/
.heroContainer {
	display: flex;
	flex-direction: column;
	background-color: var(--white-web-space);
}

.logo img {
	width: 7.2rem;
}

.heroContainer__texto {
	padding-left: 1.6rem;
}

.heroContainer__texto .heroContainer__texto--1 {
	margin-top: 7.3rem;
	font-family: "Akzidenz-Grotesk Pro Regular", sans-serif;
	font-size: 1.6rem;
	font-weight: 400;
	color: #000000;
	width: 27.4rem;
	height: 1.6rem;
}

.heroContainer .heroContainer__texto--2 {
	line-height: 2rem;
	margin-bottom: 1.2rem;
}

.heroContainer__texto h1 {
	margin-top: 1.6rem;
	font-family: "Akzidenz-Grotesk Pro Med";
	font-size: 3.6rem;
	font-weight: 500;
	line-height: 4rem;
	max-width: 35rem;
	height: 8.4rem;
}

.heroContainer__texto p {
	margin-top: 1.6rem;
	font-family: "Akzidenz-Grotesk Pro Light";
	font-size: 1.6rem;
	font-weight: 300;
	max-width: 33.7rem;
	height: 6rem;
	color: #00000099;
}

.heroContainer__imagenes img {
	border-radius: 5rem;
	box-shadow: #0000001a 1.6rem 1.6rem 2rem;
	margin-bottom: 1.6rem;
}

.heroContainer__imagenes {
	display: flex;
	gap: 1.6rem;
	overflow-x: hidden;
	background-color: var(--white-web-space);
	padding-top: 2.4rem;
	padding-bottom: 4rem;
	padding-left: 1.6rem;
}

.heroContainer__col {
	height: fit-content;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
}

.heroContainer__col--left {
	width: 52.94vw;
}

.heroContainer__col--right {
	margin-top: 7.2rem;
	width: 47.06vw;
}
/* Segunda parte, debajo del Hero*/

.segundaParte {
	padding-left: 1.6rem;
	padding-right: 1.6rem;
}

.titulo--centrado {
	display: flex;
	justify-content: center;
	margin: auto;
}

.segundaParte__titulo {
	font-size: 2.2rem;
	font-weight: 500;
	height: auto;
	padding-top: 4rem;
	color: var(--violet-dark-web-space);
	font-family: "Akzidenz-Grotesk Pro Med";
	line-height: 2.4rem;
}

.segundaParte__p1 {
	font-size: 1.4rem;
	margin-top: 3.2rem;
	font-weight: 300;
	font-family: "Akzidenz-Grotesk Pro Light";
	color: #00000099;
	height: auto;
	line-height: 1.819rem;
}

.p1--margen {
	margin-top: 2.6rem;
}

.segundaParte__p2 {
	font-size: 1.4rem;
	line-height: 1.8rem;
	font-family: "Akzidenz-Grotesk Pro Light";
	margin-top: 2.4rem;
	color: black;
}

.segundaParte__ilustracion1 {
	width: 10.6rem;
	height: 10.6rem;
	margin-top: 4rem;
}

.segundaParte__subtitulo {
	font-weight: 500;
	font-size: 1.4rem;
	line-height: 1.6rem;
	color: var(--violet-dark-web-space);
	font-family: "Akzidenz-Grotesk Pro Med";
	margin-top: 0.8rem;
	text-align: center;
}

.p1--resaltado {
	font-weight: 400;
	font-family: "Akzidenz-Grotesk Pro Regular";
}

.align-center {
	text-align: center;
}

.p1--margen2 {
	margin-top: 2rem;
}

.segundaParte--centrado {
	display: grid;
	place-items: center;
}

.centrado--1 {
	display: grid;
	place-items: center;
}

.ilustracion1--variante {
	width: 10.88rem;
	height: 8.96rem;
}

.subtitulo--tamaño {
	width: 25.7rem;
	height: 3.6rem;
	line-height: 1.6rem;
	margin-top: 2.143rem;
}

.img-desk2 {
	margin-top: 6.4rem;
}

.centrado--margen {
	margin-top: 4.4rem;
}

.segundaParte__clientes {
	font-size: 1.6rem;
	font-weight: 500;
	font-family: "Akzidenz-Grotesk Pro Med Ext";
	margin-top: 1.6rem;
	margin-bottom: 1.6rem;
	color: #000000;
}

.clientes__texto {
	font-size: 1.4rem;
	font-weight: 300;
	color: #00000099;
	font-family: "Akzidenz-Grotesk Pro Light";
	width: 24.2rem;
	height: auto;
	text-align: center;
	line-height: 1.8rem;
}

.texto--tamaño {
	width: 25.2rem;
}

.titulo--margen2 {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-top: 0;
}

.imagen--descripcion {
	font-size: 1.6rem;
	font-weight: 300;
	line-height: 1.8rem;
	font-family: "Akzidenz-Grotesk Pro Light", sans-serif;
	font-style: italic;
	width: 33.2rem;
	text-align: center;
	margin-top: 3.2rem;
	margin-bottom: 3.2rem;
	color: #000000;
}

.containerCliente {
	display: flex;
}

.texto--1 {
	font-size: 1.4rem;
	font-weight: 500;
	font-family: "Akzidenz-Grotesk Pro Med Ext";
	margin-bottom: 0.4rem;
	color: #000000;
}

.texto--2 {
	font-size: 1.2rem;
	font-family: "Akzidenz-Grotesk Pro Light";
	color: #000000;
}

.containerCliente__texto {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 1.2rem;
}

.segundaParte__ultTitulo {
	font-size: 2.2rem;
	font-weight: 500;
	line-height: 2.7rem;
	width: 29.9rem;
	height: 8rem;
	margin-top: 6rem;
	text-align: center;
	font-family: "Akzidenz-Grotesk Pro Med Ext";
	margin-bottom: 4rem;
	background: var(--violet-light-gradient);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
}

.containerPalabras {
	display: flex;
	margin-top: 4rem;
	margin-bottom: 4rem;
	column-gap: 1.9rem;
}

.containerPalabras__elem {
	display: flex;
	align-items: center;
	gap: 0.6rem;
}

.elem__container {
	width: 2rem;
	height: 2rem;
	border-color: var(--violet-dark-web-space);
	border-radius: 50%;
	border-style: solid;
	border-width: 1px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.tick {
	width: 1.25rem;
	height: 1.25rem;
}

.containerPalabras__elem p {
	font-size: 1.4rem;
	font-weight: 300;
	font-family: "Akzidenz-Grotesk Pro Light Ext";
}

.btn--centrado {
	margin: 0;
	display: flex;
	justify-content: center;
	margin-left: -1.6rem;
}

.elem--margen {
	margin-right: 2.2rem;
	margin-left: 2.2rem;
}

.barra--margen1 {
	margin-top: 3.2rem;
	margin-bottom: 0.8rem;
	margin-left: 1.6rem;
	margin-right: 1.6rem;
}

.barra--margen2 {
	position: relative;
	top: -3.2rem;
	margin-bottom: 1.6rem;
	margin-left: 1.6rem;
	margin-right: 1.6rem;
}

#titulo--desk {
	display: none;
}

.btnConocePropuesta {
	display: none;
}

.btn--desk {
	display: none;
}

.desk2--grupo {
	display: grid;
	place-items: center;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	margin-bottom: 3.2rem;
}

.centrado--2 {
	display: grid;
	place-items: center;
}

.containerCliente img {
	width: 5.6rem;
}

.btn--desk2 {
	display: none;
}

.centrado--3 {
	display: grid;
	place-items: center;
}

.centrado--4 {
	display: grid;
	place-items: center;
}

.centrado--5 {
	display: grid;
	place-items: center;
}

.bodyIndex {
	overflow-x: hidden;
}

.desk2__picture {
	padding: 0 1.6rem;
}

.heroContainer__leftImage {
	width: 100%;
	display: block;
	height: 35.29vw;
	border-radius: 5rem;
}

.heroContainer__leftImage--first {
	background: url("../../../assets/images/index/persona1.png?as=webp&width=512")
		lightgray -3.8rem 0px / 118.519% 100% no-repeat;
}

.heroContainer__leftImage--second {
	background: url("../../../assets/images/index/persona2.png?as=webp&width=512")
		lightgray -38px 0px / 118.519% 100% no-repeat;
}

.heroContainer__rightImage {
	width: 100%;
	display: block;
	height: 35.29vw;
	border-radius: 5rem 0 0 5rem;
}

.heroContainer__rightImage--first {
	background: url("../../../assets/images/index/persona3.png?as=webp&width=512")
		lightgray 50% / cover no-repeat;
}

.heroContainer__rightImage--second {
	background: url("../../../assets/images/index/persona4.png?as=webp&width=512")
		lightgray 50% / cover no-repeat;
}

.segundaParte__image {
	width: 24.8rem;
}

.segundaParte__image img {
	border-radius: 5rem;
}

.SegundaParte--desk4 {
	margin-bottom: 3.2rem;
}

.SegundaParte--desk2 {
	padding-left: 1.6rem;
	padding-right: 1.6rem;
}

.titulo--margen4 {
	margin-top: 3.2rem;
}

p.ml {
	margin-left: 3.2rem !important;
}

.segundaParte--desk1 {
	margin-bottom: 3.2rem;
}

.text-mb {
	margin-bottom: 2.6rem;
}

.title--mt {
	margin-top: 4.8rem;
}

.blogs {
	display: flex;
	flex-direction: column;
	gap: 4.8rem;
	padding: 4.8rem 1.5rem 0 1.5rem;
}

.blogs__card {
	display: flex;
	flex-direction: column;
	border-radius: 2rem;
}

.blogs__picture {
	border-radius: 20px 20px 0px 0px;
	overflow-y: hidden;
	height: 13rem;
}

.blogs__picture img {
	border-radius: inherit;
}

@media (min-width: 1024) {
	.heroContainer {
		flex-direction: row;
	}

	.heroContainer__col--left {
		display: none;
	}
}
