.heroContainer {
  background-color: var(--white-web-space);
  flex-direction: column;
  display: flex;
}

.logo img {
  width: 7.2rem;
}

.heroContainer__texto {
  padding-left: 1.6rem;
}

.heroContainer__texto .heroContainer__texto--1 {
  color: #000;
  width: 27.4rem;
  height: 1.6rem;
  margin-top: 7.3rem;
  font-family: Akzidenz-Grotesk Pro Regular, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
}

.heroContainer .heroContainer__texto--2 {
  margin-bottom: 1.2rem;
  line-height: 2rem;
}

.heroContainer__texto h1 {
  max-width: 35rem;
  height: 8.4rem;
  margin-top: 1.6rem;
  font-family: Akzidenz-Grotesk Pro Med;
  font-size: 3.6rem;
  font-weight: 500;
  line-height: 4rem;
}

.heroContainer__texto p {
  color: #0009;
  max-width: 33.7rem;
  height: 6rem;
  margin-top: 1.6rem;
  font-family: Akzidenz-Grotesk Pro Light;
  font-size: 1.6rem;
  font-weight: 300;
}

.heroContainer__imagenes img {
  border-radius: 5rem;
  margin-bottom: 1.6rem;
  box-shadow: 1.6rem 1.6rem 2rem #0000001a;
}

.heroContainer__imagenes {
  background-color: var(--white-web-space);
  gap: 1.6rem;
  padding-top: 2.4rem;
  padding-bottom: 4rem;
  padding-left: 1.6rem;
  display: flex;
  overflow-x: hidden;
}

.heroContainer__col {
  flex-direction: column;
  gap: 1.6rem;
  height: fit-content;
  display: flex;
}

.heroContainer__col--left {
  width: 52.94vw;
}

.heroContainer__col--right {
  width: 47.06vw;
  margin-top: 7.2rem;
}

.segundaParte {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.titulo--centrado {
  justify-content: center;
  margin: auto;
  display: flex;
}

.segundaParte__titulo {
  color: var(--violet-dark-web-space);
  height: auto;
  padding-top: 4rem;
  font-family: Akzidenz-Grotesk Pro Med;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 2.4rem;
}

.segundaParte__p1 {
  color: #0009;
  height: auto;
  margin-top: 3.2rem;
  font-family: Akzidenz-Grotesk Pro Light;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.819rem;
}

.p1--margen {
  margin-top: 2.6rem;
}

.segundaParte__p2 {
  color: #000;
  margin-top: 2.4rem;
  font-family: Akzidenz-Grotesk Pro Light;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.segundaParte__ilustracion1 {
  width: 10.6rem;
  height: 10.6rem;
  margin-top: 4rem;
}

.segundaParte__subtitulo {
  color: var(--violet-dark-web-space);
  text-align: center;
  margin-top: .8rem;
  font-family: Akzidenz-Grotesk Pro Med;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
}

.p1--resaltado {
  font-family: Akzidenz-Grotesk Pro Regular;
  font-weight: 400;
}

.align-center {
  text-align: center;
}

.p1--margen2 {
  margin-top: 2rem;
}

.segundaParte--centrado, .centrado--1 {
  place-items: center;
  display: grid;
}

.ilustracion1--variante {
  width: 10.88rem;
  height: 8.96rem;
}

.subtitulo--tamaño {
  width: 25.7rem;
  height: 3.6rem;
  margin-top: 2.143rem;
  line-height: 1.6rem;
}

.img-desk2 {
  margin-top: 6.4rem;
}

.centrado--margen {
  margin-top: 4.4rem;
}

.segundaParte__clientes {
  color: #000;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  font-family: Akzidenz-Grotesk Pro Med Ext;
  font-size: 1.6rem;
  font-weight: 500;
}

.clientes__texto {
  color: #0009;
  text-align: center;
  width: 24.2rem;
  height: auto;
  font-family: Akzidenz-Grotesk Pro Light;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.8rem;
}

.texto--tamaño {
  width: 25.2rem;
}

.titulo--margen2 {
  justify-content: center;
  width: 100%;
  padding-top: 0;
  display: flex;
}

.imagen--descripcion {
  text-align: center;
  color: #000;
  width: 33.2rem;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  font-family: Akzidenz-Grotesk Pro Light, sans-serif;
  font-size: 1.6rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.8rem;
}

.containerCliente {
  display: flex;
}

.texto--1 {
  color: #000;
  margin-bottom: .4rem;
  font-family: Akzidenz-Grotesk Pro Med Ext;
  font-size: 1.4rem;
  font-weight: 500;
}

.texto--2 {
  color: #000;
  font-family: Akzidenz-Grotesk Pro Light;
  font-size: 1.2rem;
}

.containerCliente__texto {
  flex-direction: column;
  justify-content: center;
  margin-left: 1.2rem;
  display: flex;
}

.segundaParte__ultTitulo {
  text-align: center;
  background: var(--violet-light-gradient);
  color: #0000;
  background-clip: text;
  width: 29.9rem;
  height: 8rem;
  margin-top: 6rem;
  margin-bottom: 4rem;
  font-family: Akzidenz-Grotesk Pro Med Ext;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 2.7rem;
}

.containerPalabras {
  column-gap: 1.9rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
}

.containerPalabras__elem {
  align-items: center;
  gap: .6rem;
  display: flex;
}

.elem__container {
  border-color: var(--violet-dark-web-space);
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  display: flex;
}

.tick {
  width: 1.25rem;
  height: 1.25rem;
}

.containerPalabras__elem p {
  font-family: Akzidenz-Grotesk Pro Light Ext;
  font-size: 1.4rem;
  font-weight: 300;
}

.btn--centrado {
  justify-content: center;
  margin: 0 0 0 -1.6rem;
  display: flex;
}

.elem--margen {
  margin-left: 2.2rem;
  margin-right: 2.2rem;
}

.barra--margen1 {
  margin: 3.2rem 1.6rem .8rem;
}

.barra--margen2 {
  margin-bottom: 1.6rem;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  position: relative;
  top: -3.2rem;
}

#titulo--desk, .btnConocePropuesta, .btn--desk {
  display: none;
}

.desk2--grupo {
  place-items: center;
  margin-bottom: 3.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  display: grid;
}

.centrado--2 {
  place-items: center;
  display: grid;
}

.containerCliente img {
  width: 5.6rem;
}

.btn--desk2 {
  display: none;
}

.centrado--3, .centrado--4, .centrado--5 {
  place-items: center;
  display: grid;
}

.bodyIndex {
  overflow-x: hidden;
}

.desk2__picture {
  padding: 0 1.6rem;
}

.heroContainer__leftImage {
  border-radius: 5rem;
  width: 100%;
  height: 35.29vw;
  display: block;
}

.heroContainer__leftImage--first {
  background: #d3d3d3 url("persona1.d5488fc9.webp") -3.8rem 0 / 118.519% 100% no-repeat;
}

.heroContainer__leftImage--second {
  background: #d3d3d3 url("persona2.2cb1c384.webp") -38px 0 / 118.519% 100% no-repeat;
}

.heroContainer__rightImage {
  border-radius: 5rem 0 0 5rem;
  width: 100%;
  height: 35.29vw;
  display: block;
}

.heroContainer__rightImage--first {
  background: #d3d3d3 url("persona3.0d2d6294.webp") 50% / cover no-repeat;
}

.heroContainer__rightImage--second {
  background: #d3d3d3 url("persona4.11cd9dca.webp") 50% / cover no-repeat;
}

.segundaParte__image {
  width: 24.8rem;
}

.segundaParte__image img {
  border-radius: 5rem;
}

.SegundaParte--desk4 {
  margin-bottom: 3.2rem;
}

.SegundaParte--desk2 {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.titulo--margen4 {
  margin-top: 3.2rem;
}

p.ml {
  margin-left: 3.2rem !important;
}

.segundaParte--desk1 {
  margin-bottom: 3.2rem;
}

.text-mb {
  margin-bottom: 2.6rem;
}

.title--mt {
  margin-top: 4.8rem;
}

.blogs {
  flex-direction: column;
  gap: 4.8rem;
  padding: 4.8rem 1.5rem 0;
  display: flex;
}

.blogs__card {
  border-radius: 2rem;
  flex-direction: column;
  display: flex;
}

.blogs__picture {
  border-radius: 20px 20px 0 0;
  height: 13rem;
  overflow-y: hidden;
}

.blogs__picture img {
  border-radius: inherit;
}

@media (width >= 1024px) {
  .heroContainer {
    flex-direction: row;
  }

  .heroContainer__col--left {
    display: none;
  }
}
/*# sourceMappingURL=blogs.4c3b8497.css.map */
